import util from '@bmp-vue/core/api/util'
import api from '../base/ControllerUtil'

const controller = 'Operador'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: (dto) => {
    return api.Axios.GetParams(url, dto)
  },
  ListarByCodigoAnonymous: (id) => {
    return api.Axios.Get(url, id)
  },
  ListarByCod: (dto) => {
    return api.Axios.Get(url, dto)
  },
  ListarByCodEmpresa: (dto) => {
    return api.Axios.GetParams(url + '/ByCodigoEmpresa', dto)
  },
  ListarByEmail: (dto) => {
    return api.Axios.GetParams(url + '/Email/' + dto.email)
  },
  Atualizar: (data) => {
    return api.Axios.Put(url, '', data)
  },
  PrimeiroAcesso: (data) => {
    return api.Axios.PutAnonymous(url + '/PrimeiroAcesso', '', data)
  },
  ReenvioEmailPrimeiroAcesso: (data) => {
    return api.Axios.PutAnonymous(url + '/ReenvioEmailPrimeiroAcesso', '', data)
  },
  Inserir: (data) => {
    return api.Axios.Post(url, data)
  },
  UpdateSenha: (data) => {
    const updateSenhaUrl = url + '/UpdateSenha';
    return api.Axios.Put(updateSenhaUrl, false, data);
  },
  UpdateSenhaToken: (data) => {
    const updateSenhaUrl = url + '/UpdateSenhaToken';
    return api.Axios.PostAnonymous(updateSenhaUrl, data, null);
  },
  GetTokenSenha: (email) => {
    const getTokenUrl = url + '/GetTokenSenha';
    return api.Axios.PostAnonymous(getTokenUrl, email, null)
  },
  UpdateOperadorBloqueado: (data) => {
    return api.Axios.Post(url + '/UpdateBloqueado', data)
  },

  MigrarUsuarioSSO: data => {
    return api.Axios.Post(url + '/UpdateOperadorSSOMigrar', data)
  }
}, util.GetCrud(controller, null), {});
