const FormatURLParams = dto => {
  dto = FilterSortByDesc(dto)
  dto = RemoveEmptyArrays(dto)

  return new URLSearchParams(dto)
}

const FilterSortByDesc = dto => {
  if (
    'sortBy' in dto &&
    'sortDesc' in dto &&
    !dto.sortBy.length &&
    dto.sortDesc.length
  ) {
    delete dto.sortBy
    delete dto.sortDesc
  }
  return dto
}

const RemoveEmptyArrays = dto => {
  return Object.fromEntries(
    Object.entries(dto).filter(obj => {
      const value = obj[1]
      if (Array.isArray(value) && !value.length) return false
      return !!(value || value === 0)
    })
  )
}

export default FormatURLParams
