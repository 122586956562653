import api from '../base/ControllerUtil'

const controller = 'Empresa'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: dto => (
    api.Axios.GetParams(url, dto)
  ),
  ListarNomes: () => (
    api.Axios.GetParams(url + '/Nome')
  ),
  ListarByPortal: dto => (
    api.Axios.GetParams(url + '/ConveniadasPortal', dto)
  ),
  ListarFiltroLotes: dto => (
    api.Axios.GetParams(url + '/FiltroLotes', dto)
  ),
  Inserir: dto => (
    api.Axios.Post(url, dto)
  ),
  Update: (data) => (
    api.Axios.Put(url, null, data)
  )
}, api.GetCrud(controller, null), {});
