import api from '../base/ControllerUtil'

const controller = 'ImportacaoLiquidacao'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Importar: dto => api.Axios.Post(url + '/Padrao', dto),
  
  ImportarAgenda: dto => (
    api.Axios.Post(url, dto)
  ),
}, api.GetCrud(controller, null), {});
