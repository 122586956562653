import util from '@bmp-vue/core/api/util'
import api from '../base/ControllerUtil'

const controller = 'SolicitacaoParcela'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: (dto) => {
    return api.Axios.GetParams(url, dto)
  },

  ListarParcelaByCodigoUsuario: codigoUsuario => (
    api.Axios.Get(url + '/ListarParcelaByCodigoUsuario', codigoUsuario)
  ),

  ListarParcelaByCodigoSolicitacao: dto => (
    api.Axios.Get(url, dto)
  ),

  Liquidar: codigoParcela => (
    api.Axios.Put(url + '/Liquidar?Codigo=' + codigoParcela)
  ),

  Estornar: codigoParcela => (
    api.Axios.Put(url + '/Estornar?Codigo=' + codigoParcela)
  ),
}, util.GetCrud(controller, null), {});
