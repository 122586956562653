import api from '../base/ControllerUtil'

const controller = 'Usuario'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: dto => (
    api.Axios.GetParams(url, dto)
  ),

  Inserir: dto => (
    api.Axios.Post(url, dto)
  ),

  Atualizar: data => (
    api.Axios.Put(url, '', data)
  ),

  AtualizarSituacao: data => (
    api.Axios.Put(url + '/UpdateSituacao', '', data)
  ),

  Desbloquear: dto => (
    api.Axios.Post(url + '/Desbloquear', dto)
  )
}, api.GetCrud(controller, null), {});
