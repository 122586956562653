import util from '@bmp-vue/core/api/util'
import api from '../base/ControllerUtil'

const controller = 'OperadorEmpresa'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  ListarByCodigoOperador: (id) => {
    return api.Axios.Get(url, id)
  }
}, util.GetCrud(controller, null), {});
