import util from '@bmp-vue/core/api/util'

const controller = 'MonitoramentoEmail'

export default Object.assign({
  getData: (url) => {
    return util.Axios.Get(url)
  },

  ReprocessarEmail: (url, id) => {
    return util.Axios.Put(url, id)
  }
}, util.GetCrud(controller, null), {});
