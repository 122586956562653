<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
    data-cy="menu-date"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="input"
        :label="label"
        v-model="dateInput"
        v-mask="'##/##/####'"
        outlined
        dense
        prepend-inner-icon="event"
        v-bind="attrs"
        v-on="on"
        data-cy="text-date"
        :rules="rules"
        :selected="model.selected"
      />
    </template>
    <v-date-picker
      v-model="datePicker"
      no-title
      :min="min"
      :max="max"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>

export default {
  props: {
    value: Date,
    min: String,
    max: String,
    label: { default: '' },
    selected: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      menu: false,
      datePicker: '',
      dateInput: '',
      rules: [
        v => this.required ? !!v || 'Data é requerida' : null,
        v => {
          if (v === '' || !v || v == null) {
            return true;
          }

          return (/^\d{2}\/\d{2}\/\d{4}$/.test(v) && v !== '') || 'Data deve ser válida'
        },
      ],
      model: {
        data: []
      }
    }
  },

  computed: {
    date: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    datePicker (value) {
      this.dateInput = value.split('-').reverse().join('/')
    },
    dateInput (value) {
      if (!value) return

      const pos = this.$refs.input.$refs.input.selectionStart
      this.$nextTick(() => {
        if (value.length !== pos) {
          this.$refs.input.$refs.input.selectionEnd = this.formatToDate(value)
        }
      })
    },
    date (value) {
      if (value !== this.reverterData(value)) {
        this.dateInput = this.reverterData(value)
      }
    }
  },
  mounted () {
    if (this.value && this.value.toISOString().indexOf('T') !== -1) {
      this.dateInput = this.reverterData(this.value.toISOString());
    }
  },
  methods: {
    reverterData: data => data ? new Date(data).toLocaleDateString() : null,

    formatToDate: date => {
      const parts = date.split('/')

      return new Date(parts[2], (parts[1] - 1), parts[0])
    }
  }
}
</script>
