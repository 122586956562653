import api from '../base/ControllerUtil'

const controller = 'Portal'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Insert: dto => (
    api.Axios.Post(url, dto)
  ),
  Listar: (dto) => (
    api.Axios.GetParams(url, dto)
  ),
  ListarNomes: () => (
    api.Axios.GetParams(url + '/Nome')
  ),
  Update: (data) => (
    api.Axios.Put(url, data.codigo, data)
  ),
}, api.GetCrud(controller, null), {});
