import util from '@bmp-vue/core/api/util'
import api from './base/ControllerUtil'

const controller = 'Dashboard'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  ListarTotalUltimoAno: (dto) => {
    return api.Axios.GetParams(url + '/TotalUltimoAno', dto)
  },
  ListarPagaUltimoAno: (dto) => {
    return api.Axios.GetParams(url + '/PagaUltimoAno', dto)
  }
}, util.GetCrud(controller, null), {});