import colors from 'vuetify/es5/util/colors'
var themes = {
  light: {
    primary: colors.blue.darken2,
    secondary: colors.grey.darken2,
    accent: colors.blue.accent1,
    error: colors.red.accent2,
    info: colors.blue,
    success: colors.green,
    warning: colors.amber
  },
  dark: {
    primary: colors.blue.darken2,
    secondary: colors.grey.lighten2,
    accent: colors.blue.accent1,
    error: colors.red.accent2,
    info: colors.blue,
    success: colors.green,
    warning: colors.amber
  }
}

export default {
  dark: false,
  options: {
    customProperties: true,
    grid: {
      rows: 10,
      pageSize: [10, 20, 30, -1],
    }
  },
  themes: themes
}
