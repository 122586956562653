import api from '../base/ControllerUtil'

const controller = 'LoteRecebimento'
const url = process.env.VUE_APP_ROOT_API + controller;

const endpoints = {
  Get: dto => (
    api.Axios.GetParams(url, dto)
  ),
  Post: dto => (
    api.Axios.Post(url, dto)
  ),
  Put: (url, data) => (
    api.Axios.Put(url, data)
  ),
  ListarParcelasDaAgenda: dto => (
    api.Axios.Post(url + '/Agenda/ConsultarParcelas', dto)
  ),
  ListarParcelasDaAgendaPropostasDiferentes: dto => (
    api.Axios.Post(url + '/Agenda/ConsultarParcelasPropostasDiferentes', dto)
  ),
  GerarLote: dto => (
    api.Axios.Post(url, dto)     
  ),
  GerarBoletoUnico: dto => (
    api.Axios.Post(url + '/Agenda/GeraUnicoBoleto', dto)
  ),
  ListarBoletosDaAgenda: dto => (
    api.Axios.Post(url + '/Agenda/ConsultaBoleto', dto)
  ),
  ListarDetalhesParcelasDaAgenda: dto => (
    api.Axios.Post(url + '/Agenda/ConsultaDetalhes', dto)
  ),
  ConsultaDetalhesLote: dto => (
    api.Axios.Post(url + '/LoteDetalhes', dto)
  ),
  CancelarLote: dto => (
    api.Axios.Put(url + '/CancelarLote', '', dto)
  ),
  GetParcelas: dto => (
    api.Axios.GetParams(url + '/SolicitacoesPendentes', dto)
  ),
  CancelarBoleto: dto => (
    api.Axios.Post(url + '/Agenda/CancelarBoleto', dto)
  ),
  EnvioEmail: dto => (
    api.Axios.Post(url + '/EnvioEmail', dto)
  ),
  ImprimirLote: dto => (
    api.Axios.PostAnonymous(url + '/ImprimirLote', dto)
  ),
  LiquidarLote: dto => (
    api.Axios.Post(url + '/LiquidarLote', dto)
  ),
  ConsultarCobrancaConveniadaDetalhesLancamento: dto => (
    api.Axios.GetParams(url + '/CobrancaConveniadaDetalhesLancamento', dto)
  ),
  ReprocessaLancamentoParcela: dto => (
    api.Axios.Post(url + '/ReprocessaLancamentoParcela', dto)
  )
}

export default endpoints
