import util from '@bmp-vue/core/api/util'
import api from '../controllers/base/ControllerUtil'

const controller = 'Autenticacao'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, util.GetCrud(controller, ['none']), {
  Autenticar: function (sender) {
    var dto = { login: sender.email, password: sender.password }
    return api.Axios.PostAnonymous(url + '/Login', dto)
  },
  Logout: function (token) {
    return util.Axios.Delete(controller + '/Logout/' + token)
  },
  RefreshToken: function (token) {
    return util.Axios.Post(controller + '/RefreshToken', { refreshToken: token })
  },
  RevokeRefreshToken: function (token) {
    return util.Axios.Delete(controller + 'RefreshToken', token)
  },
  LinkSenha: function (email) {
    var dto = { userName: email}
    return util.Axios.Post(controller + '/LinkSenha', dto)
  },
  RecuperarSenha: function (dto) {
    return util.Axios.Post(controller + '/RecuperarSenha', dto)
  },
  ValidaTokenSenha: function (username, token) {
    const params = { username: username, token: token }
    return util.Axios.GetParams(controller + '/ValidaTokenSenha', params)
  }
})
