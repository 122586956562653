import util from '@bmp-vue/core/api/util'
import api from '../base/ControllerUtil'

const controller = 'Solicitacao'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: (dto) => {
    return api.Axios.GetParams(url, dto)
  },
  ListarByCodigo: (id) => {
    return api.Axios.Get(url, id)
  },
  AprovarReprovarCancelar: (dto) => (    
    api.Axios.Put(url + '/AprovarReprovarCancelar', '', dto)
  ),
  CalcularParcelas: (dto) => (
    api.Axios.Post(url + '/CalcularParcelas', dto)
  ),
  ListaSolicitacaoByUser: (codigo) => (
    api.Axios.Get(url + '/GetSolicitacaoByUserCode', codigo)
  )
}, util.GetCrud(controller, null), {});
