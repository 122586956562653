<template v-slot="toolbar-user-options">
  <v-list-item link @click="profileAccess" data-cy="toolbar-list-item-logout">
    <v-list-item-title data-cy="toolbar-list-item-logout-title">
      <v-icon small class="pr-2" data-cy="toolbar-list-item-logout-icon">
        mdi-account-cog
      </v-icon>
      Editar Perfil
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  methods: {
    profileAccess () {
      if (this.$route.path !== '/profile') {
        this.$router.push('/profile');
      }
    }
  }
}
</script>
