import api from '../base/ControllerUtil'

const controller = 'ExportacaoVencimentos'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: dto => (
    api.Axios.GetParams(url, dto)
  )
}, {});
