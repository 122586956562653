export default [
  {
    path: '/',
    view: 'Login',
    title: 'Login',
    dir: 'views/',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/primeiroacesso',
    view: 'PrimeiroAcesso',
    title: 'Primeiro Acesso',
    dir: 'views/',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/profile',
    view: 'Profile',
    title: 'Perfil',
    dir: 'views/',
    menu: false,
    meta: {
    }
  },
  {
    path: '/solicitacaodetalhe/:id',
    view: 'SolicitacaoDetalhe',
    dir: 'views/operacoes/solicitacao/',
    menu: false,
    meta: {
    }
  },
  {
    title: 'Dashboard',
    icon: 'mdi-chart-donut-variant',
    path: '/dashboard',
    view: 'Dashboard',
    dir: 'views/',
    menu: true,
    meta: {
    }
  },
  {
    path: '/cadastros',
    icon: 'mdi-account-cog',
    title: 'Cadastros',
    dir: 'views/cadastros/',
    menu: true,
    meta: {
      allRolesRequired: false
    },
    items: [
      {
        path: '/operadores',
        title: 'Operadores',
        view: 'operador/Operador',
        menu: true,
        meta: {
        }
      },
      {
        path: '/funcionarios',
        title: 'Funcionários',
        view: 'funcionario/Funcionarios',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      },
      {
        path: '/portais',
        title: 'Portais',
        view: 'portal/Portal',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      },
      {
        path: '/empresas',
        title: 'Empresas',
        view: 'empresa/Empresas',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: '/operacoes',
    icon: 'mdi-badge-account-horizontal-outline',
    title: 'Operações',
    dir: 'views/operacoes/',
    menu: true,
    meta: {
      allRolesRequired: false
    },
    items: [
      {
        path: '/solicitacoes',
        title: 'Solicitações',
        view: 'solicitacao/Solicitacoes',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      },
      {
        path: '/relatorioparcelas',
        title: 'Relatório de parcelas',
        view: 'relatorioparcelas/RelatorioParcelas',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      },
      {
        path: '/importacaoliquidacao',
        title: 'Importar liquidações',
        view: 'liquidacao/liquidacoes/Liquidacoes',
        menu: false,
        meta: {
          allRolesRequired: false
        }
      },
      {
        path: '/lancamentoparcelas',
        title: 'Lançamento de parcelas',
        view: 'liquidacao/lancamentoparcelas/LancamentoParcelas',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      },
      {
        path: '/Lote',
        title: 'Lote cobrança',
        menu: false,
        meta: {
          allRolesRequired: false
        },
        items: [
          {
            path: '/lotes',
            title: 'Lotes',
            view: 'lotecobranca/lotes/Lotes',
            menu: true,
            meta: {
              allRolesRequired: false
            }
          }
        ]
      },
      {
        path: '/cobrancaconveniadas',
        title: 'Cobrança de conveniadas',
        view: 'lotecobranca/cobrancaconveniadas/CobrancaConveniadas',
        menu: true,
        meta: {
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: '/monitoramento',
    icon: 'mdi-monitor-eye',
    title: 'Monitoramento',
    dir: 'views/monitoramento/',
    menu: true,
    meta: {
      allRolesRequired: false
    },
    items: [
      {
        path: '/monitoramentoemail',
        title: 'E-mail',
        view: 'email/MonitoramentoEmails',
        menu: true,
        meta: {
        }
      },
      {
        path: '/monitoramentocallback',
        title: 'Callback',
        view: 'callback/MonitoramentoCallback',
        menu: true,
        meta: {
        }
      }
    ]
  },
  {
  path: '/redefinirsenha',
  view: 'RedefinirSenha',
  title: 'Redefinir Senha',
  dir: 'views/',
  menu: false,
  meta: {
    anonymousAuth: true
  }
},
{
  path: '/imprimirLote',
  view: 'ImprimirLote',
  title: 'Imprimir Lote',
  dir: 'views/',
  menu: false,
  meta: {
    anonymousAuth: localStorage.getItem('status') !== null ? localStorage.getItem('status') === 'loggedOut' : true
  }
},
];
