import Axios from 'axios'
import { store } from '@bmp-vue/core/plugins/store'
import FormatURLParams from './axiosUtils'
import util from '@bmp-vue/core/api/util'

const axios = {
  GetParams: function (controller, params, token) {
    token = JSON.parse(localStorage.getItem('user'))?.token ?? token
    params = params ? FormatURLParams(params) : {}
    store.commit('addRequest')
    return Axios.get(controller, {
      params,
      headers: { Authorization: `Bearer ${token}` }
    }).then(HandleResponseBase, HandleErrorBase)
  },

  Post: function (controller, dto, headers, token) {
    const dtoFormatted = this.FormatBody(dto)

    token = token ?? JSON.parse(localStorage.getItem('user')).token
    
    store.commit('addRequest')
    
    if (headers) {
      return Axios.post(controller, dtoFormatted, headers).then(
        HandleResponseBase,
        HandleErrorBase
      )
    } else {
      return Axios.post(controller, dtoFormatted, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(HandleResponseBase, HandleErrorBase)
    }
  },

  PostAnonymous: function (controller, dto, headers) {
    const dtoFormatted = this.FormatBody(dto)
    
    store.commit('addRequest')
    
    if (headers) {
      return Axios.post(controller, dtoFormatted, headers).then(
        HandleResponseBase,
        HandleErrorBase
      )
    } else {
      return Axios.post(controller, dtoFormatted).then(HandleResponseBase, HandleErrorBase)
    }
  },

  PostForm: function (controller, dto) {
    const { token } = JSON.parse(localStorage.getItem('user'))
    const dtoFormatted = this.FormatBody(dto)

    store.commit('addRequest')

    return Axios.post(controller, dtoFormatted, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${dto._boundary}`,
        Authorization: `Bearer ${token}`
      }
    }).then(HandleResponseBase, HandleErrorBase)
  },

  Put: function (controller, id, dto) {
    const { token } = JSON.parse(localStorage.getItem('user'))
    if (!dto) {
      dto = {}
    }
    store.commit('addRequest')

    const url = id ? controller + '/' + id : controller;  

    const dtoFormatted = this.FormatBody(dto)

    return Axios.put(url, dtoFormatted, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(HandleResponseBase, HandleErrorBase)
  },

  PutAnonymous: function (controller, id, dto) {
    store.commit('addRequest')
    return Axios.put(controller + '/' + id, dto || {}).then(HandleResponseBase, HandleErrorBase)
  },

  Delete: (controller, id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    store.commit('addRequest')
    return Axios.delete(controller + '/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(HandleResponseBase, HandleErrorBase)
  },
  DeleteByBody: (controller, dto) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    return Axios.delete(controller, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: dto
    }).then(HandleResponseBase, HandleErrorBase)
  },

  Get: (controller, id, token) => {
    token = JSON.parse(localStorage.getItem('user'))?.token ?? token
    store.commit('addRequest')
    return Axios.get(controller + '/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(HandleResponseBase, HandleErrorBase)
  },

  FormatBody: (dto) => {

    Object.keys(dto).forEach(function(key) {
      dto[key] = (dto[key] !== "") ? dto[key] : null
    })

    return dto
  }
}

const api = {
  ...util,
  Axios: axios,
  controller: 'UTIL', // passar UTIL apenas se quiser sobreescrever a UTIL padrão
  HandleResponseBase,
  HandleErrorBase
}

function HandleResponseBase (response) {
  store.commit('removeRequest')

  if (
    (response.count && response.data) ||
    (response.count === 0 && response.data == [])
  ) {
    return response
  }
  if (response.data.meta) {
    return response.data
  }
  if (response.data.data || response.data.data == []) {
    if (response.data.count || response.data.count === 0) {
      return response.data
    }
    return response.data.data
  }

  return response.data
}

function HandleErrorBase (error) {
  store.commit('removeRequest')
  if (error) {
    const response = error.response
    if (response) {
      if (response.data.hasError) {
        let errorMessage = '';

        if (response.data.messages.length > 1) {
          // Ainda não existem casos que cairão aqui
          const errors = []
          response.data.messages.map(x => {
            errors.push(x.description)
          })
          errorMessage = errors.join('\n')
          return { success: false, mensagem: errorMessage }
        }

        errorMessage = response.data.messages[0];
        return { success: false, mensagem: errorMessage.description }
      }

      if (typeof response.data === 'string' && response.status && response.statusText) {
        return {
          success: false, codErro: response.status, mensagem: response.data || (response.status + ' - ' + response.statusText)
        }
      }

      if (response.data) {
        if (response.data.status && response.data.title) {
          return {
            success: false, codErro: response.data.status, mensagem: response.data.status + ' - ' + response.data.title
          }
        }
        if (response.data.error) {
          return {
            success: false, codErro: response.status, mensagem: response.data.error
          }
        }
      }

      if (response.status) {
        if (!response.status.statusText && (typeof response.data === 'string' && !!response.data)) {
          return {
            sucess: false, coderro: response.status, mensagem: response.data
          }
        } else {
          return {
            success: false, codErro: response.status, mensagem: response.status + ' - ' + response.statusText
          }
        }
      }

      return response
    }
    return error
  }
  return { error: 'Erro' }
}

export default api
